import axios from "axios";
import { flowAxiosInstance } from "./axiosInterceptors"; // Import the axios instance with interceptors

export const getFlowsApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/all"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const removeFlowsApi = async (flowId) => {
  try {
    const response = await flowAxiosInstance.delete(`/flows/?flowId=${flowId}`); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
export const duplicateFlowsApi = async (flowId, newFlowId) => {
  try {
    const response = await flowAxiosInstance.post(
      `flows/duplicate?flowId=${flowId}&newFlowId=${newFlowId}`
    ); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const saveFlowsApi = async (formData, flowId) => {
  const token = localStorage.getItem("user-token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FLOW_URL}/flows/save?flowId=${flowId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Axios automatically sets the correct boundaries
          Authorization: `Bearer ${token}` // Include the token in Authorization header
        }
      }
    );
    return response;
  } catch (error) {
    console.error("Error during save:", error);
    throw error;
  }
};

export const updateFlowsApi = async (flowId, flowName, flowData) => {
  try {
    const response = await flowAxiosInstance.put(`flows/?flowId=${flowId}`, {
      flowName,
      updateFlowData: flowData
    }); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getSingleFlowDataApi = async (flowId) => {
  try {
    const response = await flowAxiosInstance.get(`flows/?flowId=${flowId}`); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getComponentsApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/components"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getVersionApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/version");
    return response;
  } catch (error) {
    console.error("Error getting version:", error);
    throw error;
  }
};

export const buildFlowApi = async (payload) => {
  try {
    const response = await flowAxiosInstance.post(
      `/flows/compile-graph/?flowId=${payload.flowId}`,
      {
        graph: payload.graph,
        required_outputs: payload.required_outputs
      }
    ); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const exportFlowCollectionApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/export");
    return response;
  } catch (error) {
    console.error("Error during exporting flows collection:", error);
    throw error;
  }
};

export const importFlowCollectionApi = async (payload) => {
  try {
    const response = await flowAxiosInstance.post("/flows/import", payload);
    return response;
  } catch (error) {
    console.error("Error during importing flows collection:", error);
    throw error;
  }
};

export const getFlowGraphImageApi = async (id) => {
  try {
    const response = await flowAxiosInstance.get(
      `/flows/get-graph-img?flowId=${id}`
    );
    return response;
  } catch (error) {
    console.error("Error during fetching flow graph image:", error);
    throw error;
  }
};

export const uploadFilesApi = async (formData, flowId) => {
  const token = localStorage.getItem("user-token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FLOW_URL}/upload/files?flowId=${flowId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during uploading files:", error);
    throw error;
  }
};

export const removeFileApi = async (flowId, fileName) => {
  try {
    const response = await flowAxiosInstance.post(
      `/flows/del-files?flowId=${flowId}`,
      {
        fileNames: [fileName]
      }
    );
    return response;
  } catch (error) {
    console.error("Error during deleting file:", error);
    throw error;
  }
};
